import { useState } from 'react';

import Form from '@/components/data-entry/Form';
import FullButton from '@/components/data-entry/FullButton';

import { FullInputEmail } from '@/components/data-entry/FullInputEmail';
import { usePMDRouter } from '@/hooks/usePMDRouter';
import { signIn } from '@/lib/auth';

export const OAuthLogIn = ({
  returnTo,
  onClick,
}: {
  returnTo?: string;
  onClick?: () => void;
}) => {
  const router = usePMDRouter();

  const subdomain = window.location.hostname.split('.')[0];
  const isE2E = subdomain === 'e2e';

  const [loggingWith, setLoggingWith] = useState('');

  const handleEmailLogin = formValues => {
    const { email } = formValues;

    signIn('email', {
      callbackUrl: returnTo || window.location.origin,
      email,
      redirect: false,
    });

    router.push('/login-verify-link');
  };
  const handleOAuth = provider => {
    signIn(provider, {
      callbackUrl: returnTo || window.location.origin,
    });
  };

  return (
    <div data-testid="container-auth-form">
      <div className="flex flex-col">
        <Form onSubmit={handleEmailLogin}>
          {({ methods }) => (
            <>
              <FullInputEmail methods={methods} placeholder="Email" required />

              <FullButton
                block
                className="mt-4"
                data-testid="btn-continue-to-login"
              >
                Get Link
              </FullButton>
            </>
          )}
        </Form>

        <div className="text-center my-5">or</div>

        <FullButton
          size="lg"
          className="mb-4 text-primary bg-[#FFFFFF] border-primary hover:text-white hover:bg-[#4285F4] hover:border-[#4285F4]"
          onClick={() => {
            setLoggingWith('google');
            handleOAuth('google');
            if (onClick) onClick();
          }}
          startIcon="google"
          variant="outline"
        >
          {loggingWith === 'google'
            ? 'Authenticating ...'
            : 'Continue with Google'}
          <div />
        </FullButton>

        {isE2E && (
          <FullButton
            data-testid="btn-login-with-email"
            size="lg"
            className="mb-4 bg-surface border-[#4267b2] hover:bg-background hover:border-[#1B76F2] text-primary"
            onClick={() => {
              setLoggingWith('auth0');
              handleOAuth('auth0');
              if (onClick) onClick();
            }}
            startIcon="email"
            variant="outline"
          >
            {loggingWith === 'auth0'
              ? 'Authenticating ...'
              : 'Continue with Email'}
          </FullButton>
        )}
      </div>
    </div>
  );
};
