import FullInput, { FullInputProps } from '@/components/data-entry/FullInput';

export const FullInputEmail = (props: FullInputProps) => {
  return (
    <FullInput
      label="Email"
      name="email"
      shouldLowerCaseInput
      shouldTrimOnBlur
      type="email"
      placeholder="joe@example.com"
      {...props}
    />
  );
};
