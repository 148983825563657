import { Maybe } from 'graphql/jsutils/Maybe';
import Router from 'next/router';

import { OrderDirection } from '@/generated/graphql';
import { usePMDRouter } from '@/hooks/usePMDRouter';

type RouterCtx = {
  [key: string]: any;
};

export const isBrowser = typeof window !== 'undefined';

/**
 * isomorphicRedirect
 *
 * Redirect for both server and browser
 *
 * @param {string} loc
 * @param {Object} ctx? Optional ctx parameter to allow for the function to work client side too
 */

export function isomorphicRedirect(loc: string, ctx: RouterCtx = {}) {
  if (ctx.req) {
    // const { host } = ctx.req.headers
    // There is an issue with the redirect not working
    // since the protocol is not included in the host property
    // ctx.res.writeHead(302, { Location: host + loc })
    ctx.res.writeHead(302, { Location: loc });
    ctx.res.end();
  } else {
    Router.push(loc);
  }
}
/**
 * isomorphicRedirectGuestTo
 *
 * Used in Page.getInitialProps to redirect
 * A GUEST (unauthenticated) user to a location
 *
 *
 * @param {string} loc
 * @param {Object} ctx? Optional ctx parameter to allow for the function to work client side too
 * @returns {Object} props that will be passed to the Page component
 */

export function isomorphicRedirectGuestTo(loc = '/', ctx: RouterCtx = {}) {
  // We need to return empty props, to prevent infinite redirects
  // caused by ctx.req being absent initially.
  if (!ctx.req) return { isLoggedIn: false };

  const userId = ctx?.req?.cookies?.userId;

  if (!userId) {
    isomorphicRedirect(loc, ctx);
    return { isLoggedIn: false };
  }

  return { isLoggedIn: true };
}

/**
 * isomorphicRedirectAuthenticatedTo
 *
 * Used in Page.getInitialProps to redirect
 * AN AUTHENTICATED USER (not guest) user to a location
 *
 *
 * @param {string} loc
 * @param {Object} ctx? Optional ctx parameter to allow for the function to work client side too
 * * @returns {Object} props that will be passed to the Page component
 */

export function isomorphicRedirectAuthenticatedTo(
  loc: string,
  ctx: RouterCtx = {}
) {
  const userId = ctx?.req?.cookies?.userId;
  if (userId) {
    isomorphicRedirect(loc, ctx);
    return { isLoggedIn: true };
  }

  return { isLoggedIn: false };
}

export const useAuthFormDetailsFromQuery = () => {
  const router = usePMDRouter();
  const { asPath } = router;

  let formTypeFromQuery;
  let formTitle;

  if (asPath.includes('/login')) {
    formTypeFromQuery = 'login';
    formTitle = 'Login';
  } else if (asPath.includes('/signup')) {
    formTypeFromQuery = 'signup';
    formTitle = 'Sign Up';
  } else if (asPath.includes('/reset')) {
    formTypeFromQuery = 'reset';
    formTitle = 'Reset Password';
  } else if (asPath.includes('/forgot')) {
    formTypeFromQuery = 'forgot';
    formTitle = 'Forgot Password';
  } else {
    formTypeFromQuery = 'login';
    formTitle = 'Login';
  }

  // const formTypeFromQuery = asPath.includes('/login')
  //   ? 'login'
  //   : asPath.includes('/signup')
  //   ? 'signup'
  //   : asPath.includes('/reset')
  //   ? 'reset'
  //   : asPath.includes('/forgot')
  //   ? 'forgot'
  //   : 'login';

  return { formTitle, formType: formTypeFromQuery };
};

const orderDirections = {
  asc: OrderDirection.Asc,
  desc: OrderDirection.Desc,
};

export const parseSortQuery = (
  sortQuery: string
): Record<string, Maybe<OrderDirection>>[] | undefined => {
  if (!sortQuery) return;

  const [fields, direction] = sortQuery.split('_');
  const fieldsArray = fields.split(',');

  const orderDirection = orderDirections[direction];

  if (!fieldsArray.length || !orderDirection) return;

  return [
    ...fieldsArray.map(field => ({ [field]: orderDirection })),
    { id: orderDirections['desc'] },
  ];
};
