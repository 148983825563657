import Cookies from 'js-cookie';

import { useEffect } from 'react';

import { OAuthLogIn } from '@/components/auth/OAuthLogIn';

import Text from '@/components/data-display/Text';

import { errorToast } from '@/components/feedback/utils/doNotify';
import FullLogoLink from '@/components/navigation/FullLogoLink';
import { usePMDRouter } from '@/hooks/usePMDRouter';

import AuthLayout from '@/layouts/AuthLayout';

import { isomorphicRedirectAuthenticatedTo } from '@/utils/router';

const LoginPage = () => {
  const router = usePMDRouter();
  const error = router.query.error;

  const cookiesUserId = Cookies.get('userId');

  useEffect(() => {
    if (error) {
      errorToast({
        autoClose: false,
        message: 'Please check your email and try again.',
        title: 'Error logging in.',
      });
    }
  }, [error]);

  useEffect(() => {
    if (cookiesUserId) {
      router.push('/');
    }
  }, [cookiesUserId, router]);

  return (
    <div className="lg:flex h-screen">
      <div className="bg-background flex-1 basis-[300px] pb-8 lg:pb-0">
        <div className="p-4">
          <FullLogoLink />
        </div>

        <div className="flex flex-col items-center pt-6 px-5">
          <div className="lg:min-w-[400px]">
            <Text block variant="h1" className="whitespace-wrap pb-3">
              Welcome to PocketMD
            </Text>
            <Text className="text-sm whitespace-wrap">
              Thank you for joining us on our journey to rebuild healthcare!
            </Text>
          </div>
        </div>
      </div>

      <div className="flex bg-surface border-l flex-col flex-[2] flex-basis-[600px] lg:min-w-[450px]">
        <div className="flex items-end border-b px-7 pb-2 basis-24 lg:basis-[245px] justify-center lg:justify-start">
          <Text className="mb-2" variant="h1">
            Let's get started!
          </Text>
        </div>

        <div className="px-7 max-w-[450px] py-5 flex justify-center mx-auto lg:block lg:mx-0">
          <OAuthLogIn returnTo="/" />
        </div>
      </div>
    </div>
  );
};

LoginPage.Layout = AuthLayout;

LoginPage.getInitialProps = async ctx => {
  return isomorphicRedirectAuthenticatedTo(ctx.query.r || '/', ctx);
};

export default LoginPage;
//
